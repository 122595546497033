<template>
  <div class="creator-terms">
    <iframe class="frame-terms" src="/terms?mode=embed" :onunload="unload"></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    unload: function() {
      console.log("unload");
    },
  },
};
</script>

<style lang="scss" scoped>
.frame-terms {
  height: 300px;
  width: 100%;
  border: none;
}
</style>
